import React from "react";

export const rightArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    viewBox="0 0 512 512"
    style={{
      minHeight: "25px",
      maxHeight: "25px",
      minWidth: "25px",
      maxWidth: "25px",
      fill: "#959595",
      cursor: "pointer",
    }}
  >
    <polygon points="160,115.4 180.7,96 352,256 180.7,416 160,396.7 310.5,256 " />
  </svg>
);

export const leftArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    viewBox="0 0 512 512"
    style={{
      minHeight: "25px",
      maxHeight: "25px",
      minWidth: "25px",
      maxWidth: "25px",
      fill: "#959595",
      cursor: "pointer",
    }}
  >
    <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " />
  </svg>
);
