import React, { useRef } from "react";
import { leftArrow, rightArrow } from "../Arrows/Arrows";
import Link from "next/link";
import Media from "react-media";
import { useRouter } from "next/router";

type PageNavProps = {
  links: Array<{
    url: string;
    name: string;
  }>;
  className: string;
};

const PageNav: React.FunctionComponent<PageNavProps> = ({
  links,
  className,
}): React.ReactElement => {
  const { asPath: currentUrl } = useRouter();
  const ref = useRef<HTMLDivElement | null>(null);
  const SCROLL_STEP = 200;

  const handleLeftClick = (): void => {
    if (ref && ref.current) {
      const currentScrollLeft = ref.current?.scrollLeft;
      ref.current.scrollLeft = currentScrollLeft - SCROLL_STEP;
    }
  };

  const handleRightClick = (): void => {
    if (ref && ref.current) {
      const currentScrollLeft = ref.current?.scrollLeft;
      ref.current.scrollLeft = currentScrollLeft + SCROLL_STEP;
    }
  };

  return (
    <div className="scrolling-menu">
      <Media query="(max-width: 765px)" defaultMatches>
        <div onClick={handleLeftClick} className="arrow arrow__left">
          {leftArrow}
        </div>
      </Media>
      <div className={`pageNav ${className}`} ref={ref}>
        {links.map((link, index) => (
          <Link
            key={index}
            passHref
            href={link.url}
            className={`pageNav__link ${
              currentUrl == link.url ? "pageNav__link--active" : ""
            }`}
          >
            {link.name}
          </Link>
        ))}
      </div>
      <Media query="(max-width: 765px)" defaultMatches={false}>
        <div onClick={handleRightClick} className="arrow arrow__right">
          {rightArrow}
        </div>
      </Media>
    </div>
  );
};

export default PageNav;
