import { StructuredData } from "../types/StructuredData";
import { ReactElement } from "react";
import { Block } from "../types/Block";
import { Question } from "../src/blocks/Faq/Questions";
import { get } from "lodash";
import { StructuredDataFaq } from "../types/StructuredDataFaq";

export class StructuredDataProvider {
  private _scripts: Array<StructuredData> = [];

  public addDataToStructuredData(block: Block): void {
    switch (block.type) {
      case "sonata.block.service.faq_block": {
        const questionsItems: Array<StructuredDataFaq> = [];
        const questions = get(block, "questions") as {
          faq: Array<Question>;
          names: string;
        };

        questions.faq.forEach((item) => {
          questionsItems.push({
            "@type": "Question",
            name: item.questions,
            acceptedAnswer: {
              "@type": "Answer",
              text: item.answers,
            },
          });
        });

        const faqDataIndex = this._scripts.findIndex(
          (script) => script["@type"] === "FAQPage"
        );

        if (faqDataIndex === -1) {
          this._scripts.push({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: questionsItems,
          });
        } else {
          const currentFaqData = this._scripts[faqDataIndex];
          this._scripts[faqDataIndex] = {
            ...currentFaqData,
            mainEntity: [
              ...(currentFaqData.mainEntity as Array<StructuredDataFaq>),
              ...questionsItems,
            ],
          };
        }

        break;
      }
    }
  }

  public getScriptsForRender(): Array<ReactElement> {
    return this._scripts.map((script, index) => (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(script) }}
        key={`structured-jsonld-${index}`}
      />
    ));
  }
}
