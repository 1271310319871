import dynamic from "next/dynamic";
export const blocksList = {
  Modes: dynamic(() => import("../blocks/Modes")),
  InNumbersBarBlock: dynamic(() => import("../blocks/Counters")),
  HtmlBlock: dynamic(() => import("../blocks/HtmlBlock")),
  ProductSliderBlock: dynamic(() => import("../blocks/ProductSlider")),
  SliderBlock: dynamic(() => import("../blocks/MainSlider")),
  TextTiles2Columns: dynamic(() => import("../blocks/TilesInColumns")),
  ProductFilteredListBlock: dynamic(
    () => import("../blocks/ProductFilteredListing")
  ),
  TilesWithIconsBlock: dynamic(() => import("../blocks/TilesWithIcons")),
  TwoColumnsBlock: dynamic(() => import("../blocks/TwoColumns")),
  Advantages: dynamic(() => import("../blocks/Advantages")),
  ExpandableTextRows: dynamic(
    () => import("../blocks/Accordion/AccordionsContainer")
  ),
  CollectingLeads: dynamic(() => import("../blocks/CollectingLeads")),
  ListWithBullets2Columns: dynamic(() => import("../blocks/ListWithBullets")),
  PayuBlock: dynamic(() => import("../blocks/PayU")),
  LinkNteo: dynamic(() => import("../blocks/Links")),
  Timeline: dynamic(() => import("../blocks/Timeline")),
  SliderHtmlBlock: dynamic(() => import("../blocks/Slider")),
  Button: dynamic(() => import("../blocks/Button")),
  Pricing: dynamic(() => import("../blocks/Pricing")),
  FaqBlock: dynamic(() => import("../blocks/Faq")),
  MovieBlock: dynamic(() => import("../blocks/Movie")),
  SliderWithTabsBlock: dynamic(() => import("../blocks/SliderWithTabs")),
  ScheduleBlock: dynamic(() => import("../blocks/ScheduleBlock")),
  Articles: dynamic(() => import("../blocks/ArticleBlock")),
  ArticlesGroup: dynamic(() => import("../blocks/ArticleGroupBlock")),
  Image: dynamic(() => import("../blocks/ImageBlock")),
  Installments: dynamic(() => import("../blocks/Installments")),
  ContentInTheTile: dynamic(() => import("../blocks/ContentInTile")),
  CodeText: dynamic(() => import("../blocks/CodeText")),
  InNumbers: dynamic(() => import("../blocks/TextWithMap")),
  Expectations: dynamic(() => import("../blocks/Expectations")),
  TestimonialBlock: dynamic(() => import("../blocks/Testimonial")),
  Numbered4Columns: dynamic(() => import("../blocks/Numbered4Columns")),
  Link: dynamic(() => import("../blocks/Link")),
  TextBlockTzop: dynamic(() => import("../blocks/Header")),
  TileListOf2Columns: dynamic(() => import("../blocks/Tile2Columns")),
  ExpandableTileCases: dynamic(() => import("../blocks/ExpandableTileCases")),
  ExternalScript: dynamic(() => import("../blocks/ExternalScript")),
  MentorsLecturers: dynamic(() => import("../blocks/Mentors")),
  StudentStories: dynamic(() => import("../blocks/StudentStories")),
  RecruitmentCompanies: dynamic(() => import("../blocks/PartnerLogos")),
  GraduateProject: dynamic(() => import("../blocks/GraduateProjects")),
  ContactCoursesBlock: dynamic(() => import("../blocks/CoursesContact")),
  ContactPatronsBlock: dynamic(() => import("../blocks/ContactCa")),
  Movie2Block: dynamic(() => import("../blocks/TwoColumnsMovie")),
  GotoShopBlock: dynamic(() => import("../blocks/GoToShop")),
  ContactBlock: dynamic(() => import("../blocks/Contact")),
  ArticleListingBlock: dynamic(() => import("../blocks/ArticlesList")),
  CoursesFilteredList: dynamic(() => import("../blocks/CoursesList")),
  RelatedPostsBlock: dynamic(() => import("../blocks/RelatedPosts")),
  ContactDepartmentsBlock: dynamic(
    () => import("../blocks/ContactDepartments")
  ),
  GroupedTimelineBlock: dynamic(() => import("../blocks/GroupedTimeline")),
  StructuredProductDataBlock: dynamic(
    () => import("../blocks/StructuredProductData")
  ),
};
