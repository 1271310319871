import Head from "next/head";
import React from "react";
import { PictureData } from "../../types/PictureData";
import { getLogoSrcForCurrentLang } from "../blocks/MainHeader/utils/LogoProvider";
import { useRouter } from "next/router";

type SEOProps = {
  description: string;
  title: string;
  picture: PictureData | null;
  canonicalUrl?: string;
};

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  title,
  picture,
  canonicalUrl,
}): React.ReactElement => {
  const { locale } = useRouter();
  const ogImageSrc =
    picture && picture.publicUrl
      ? `${process.env.NEXT_PUBLIC_IMG_URL}${picture.publicUrl}`
      : getLogoSrcForCurrentLang(locale as string);
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no"
      />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageSrc} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Head>
  );
};

export default SEO;
