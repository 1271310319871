import React, { ReactElement, useEffect } from "react";
import { PageBlocks } from "../../types/PageBlocks";
import { useRouter } from "next/router";
import RenderBlock from "./RenderBlock";
import PageNav from "../components/PageNav";

type PageWithTabsProps = {
  pages: Array<PageBlocks>;
};

const PageWithTabs: React.FunctionComponent<PageWithTabsProps> = ({
  pages,
}): React.ReactElement => {
  const { asPath: currentUrl, replace } = useRouter();
  const pageToRender = pages.find((p) => p.page.url === currentUrl);
  const blocksList: Array<ReactElement> = [];

  if (pageToRender && pageToRender.blocks) {
    pageToRender.blocks.forEach((block, index) => {
      if (block.type)
        blocksList.push(
          <RenderBlock key={`${index}_${block.type}`} blockData={block} />
        );
    });
  }

  const links = pages.map((p) => ({ name: p.page.name, url: p.page.url }));

  useEffect(() => {
    if (!pageToRender && pages.length > 0) {
      const [pageData] = pages;
      replace(pageData.page.url);
    }
  }, []);

  return (
    <>
      <PageNav links={links} className="pageTabNavigation" />
      {blocksList}
    </>
  );
};

export default PageWithTabs;
