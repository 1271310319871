import React, { ReactElement } from "react";
import { Block } from "../../types/Block";
import RenderBlock from "../dynamicPageBuilder/RenderBlock";
import PageWithTabs from "../dynamicPageBuilder/PageWithTabs";
import { StructuredDataProvider } from "../../utils/StructuredDataProvider";

type DynamicPageBuilderProps = {
  blocks: Array<Block>;
};

const DynamicPageBuilder: React.FunctionComponent<DynamicPageBuilderProps> = ({
  blocks,
}): React.ReactElement => {
  const blocksList: Array<ReactElement> = [];
  const [, pages] = blocks;
  const isPageWithTabs = Array.isArray(pages);
  const structuredDataProvider = new StructuredDataProvider();

  if (isPageWithTabs) {
    return <PageWithTabs pages={pages} />;
  }

  blocks.forEach((block, index) => {
    if (block.type) {
      structuredDataProvider.addDataToStructuredData(block);
      blocksList.push(
        <RenderBlock key={`${index}_${block.type}`} blockData={block} />
      );
    }
  });

  return (
    <>
      {structuredDataProvider.getScriptsForRender()}
      {blocksList}
    </>
  );
};

export default DynamicPageBuilder;
